import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const Responseform = () => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://192.168.29.221:8080/api/enquire'); 
        if (response.ok) {
          const data = await response.json();
          setResponseData(data);
        } else {
          setError('Failed to load response data');
        }
      } catch (error) {
        setError('There was an error fetching the data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  if (loading) {
    return (
      <div className="container text-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center">
        <h2 className="text-danger">{error}</h2>
      </div>
    );
  }

  return (
    <div
      className="container-fluid bg-light text-dark py-3 position-relative"
      style={{
        backgroundImage: 'url("https://www.kanhaearthlodge.com/blog/wp-content/uploads/2024/02/DSC01856-1-2048x1638.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <div className="overlay position-absolute top-0 start-0 w-100 h-100"></div>

      <div className="container-fluid bg-transparent text-light py-3">
        <header className="text-center">
          <h1 className="display-6">Response Information</h1>
        </header>
      </div>

      <section className="container my-2 bg-transparent w-75 w-md-50 text-light p-3">
        <div className="row g-3 p-3">
          <div className="col-12">
            <h2>Fetched Data:</h2>
            <pre>{JSON.stringify(responseData, null, 2)}</pre>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Responseform;
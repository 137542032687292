import '../App.css';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const Enquiryform = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    arrival: '',
    departure: '',
    numAdults: '',
    address: '',
    activities: {
      bikingSafari: false,
      selfDrivenSafari: false,
      chauffeurDrivenSafari: false,
      gorillaTrekkingSafari: false,
      hotAirBallooning: false,
      birdWatchingSafari: false,
    },
  });

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        activities: {
          ...prevState.activities,
          [id]: checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    console.log(formData)
    e.preventDefault();
    try {
      const response = await fetch('http://192.168.29.221:8080/api/enquire', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({
          name: '',
          email: '',
          phone: '',
          arrival: '',
          departure: '',
          numAdults: '',
          address: '',
          activities: {
            bikingSafari: false,
            selfDrivenSafari: false,
            chauffeurDrivenSafari: false,
            gorillaTrekkingSafari: false,
            hotAirBallooning: false,
            birdWatchingSafari: false,
          },
        });
      } else {
        alert("Failed to submit form. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    }
  };

  return (
    <div
      className="container-fluid bg-light text-dark py-3 position-relative"
      style={{
        backgroundImage: 'url("https://www.kanhaearthlodge.com/blog/wp-content/uploads/2024/02/DSC01856-1-2048x1638.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <div className="overlay position-absolute top-0 start-0 w-100 h-100"></div>

      <div className="container-fluid bg-transparent text-light py-3">
        <header className="text-center">
          <h1 className="display-6">Kohka Adventure Booking Form</h1>
        </header>
      </div>

      <section className="container my-2 bg-transparent w-75 w-md-50 text-light p-2">
        <form onSubmit={handleSubmit} className="row g-3 p-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">Full name</label>
            <input type="text" className="form-control" id="name" value={formData.name} onChange={handleInputChange} required />
          </div>

          <div className="col-12 col-md-6">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" value={formData.email} onChange={handleInputChange} required />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input type="tel" className="form-control" id="phone" value={formData.phone} onChange={handleInputChange} required />
          </div>
          <div className="col-md-6">
            <label htmlFor="numAdults" className="form-label">Number of Person</label>
            <input type="number" className="form-control" id="numAdults" value={formData.numAdults} onChange={handleInputChange} min="1" placeholder="0" required />
          </div>
          <div className="col-md-6">
            <label htmlFor="arrival" className="form-label">Arrival Date</label>
            <input type="date" className="form-control" id="arrival" value={formData.arrival} onChange={handleInputChange} required />
          </div>
          <div className="col-md-6">
            <label htmlFor="departure" className="form-label">Departure Date</label>
            <input type="date" className="form-control" id="departure" value={formData.departure} onChange={handleInputChange} required />
          </div>

          <div className="col-12">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" className="form-control" id="address" value={formData.address} onChange={handleInputChange} required />
          </div>
          <div className="col-12">
            <label className="form-label">What kind of activities are you interested in?</label>
            <div className="row">
              <div className="col-md-6">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="bikingSafari" checked={formData.activities.bikingSafari} onChange={handleInputChange} />
                  <label className="form-check-label">Power Paragliding</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="selfDrivenSafari" checked={formData.activities.selfDrivenSafari} onChange={handleInputChange} />
                  <label className="form-check-label">Kayaking</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="chauffeurDrivenSafari" checked={formData.activities.chauffeurDrivenSafari} onChange={handleInputChange} />
                  <label className="form-check-label">MotorBoat</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gorillaTrekkingSafari" checked={formData.activities.gorillaTrekkingSafari} onChange={handleInputChange} />
                  <label className="form-check-label">Atv Quad Biking</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="hotAirBallooning" checked={formData.activities.hotAirBallooning} onChange={handleInputChange} />
                  <label className="form-check-label">Hot Air Ballooning</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="birdWatchingSafari" checked={formData.activities.birdWatchingSafari} onChange={handleInputChange} />
                  <label className="form-check-label">Zip Line</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button type="submit" className="btn btn-primary btn-lg">Submit Form</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Enquiryform;

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Enquiryform from './components/Enquiryform';
import Responseform from './components/Responseform';

function App() {
  return (
    <BrowserRouter>
<Routes>
<Route path="/" element={<Enquiryform />} />
<Route path="/get" element={<Responseform/>} />
</Routes>
</BrowserRouter>
  );
}

export default App;